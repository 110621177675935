<template>
  <div class="exam_details">
    <Details :crumbsConfig="crumbsConfig"/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      crumbsConfig: [
        {
          url: "/",
          text: "首页"
        },
        {
          url: "/benchmarkExample",
          text: "标杆案例"
        },
        {
          text: "案例详情"
        }
      ]
    };
  },
  created() {
  },
  methods: {}
};
</script>

<style scoped lang="scss">
@media (min-width: 751px) and (max-width: 960px) {
}
@media (min-width: 961px) {
}
</style>
